import { render, staticRenderFns } from "./cms-block-column.vue?vue&type=template&id=1102afdd&scoped=true"
import script from "./cms-block-column.vue?vue&type=script&lang=js"
export * from "./cms-block-column.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1102afdd",
  null
  
)

export default component.exports