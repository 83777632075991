<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-block-selector v-for="child in block.children" :key="child.id" @block-selected="selectBlock" :block="child" :block-selection="blockSelection" is-child :editable="editable"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-column #hot-reload-debug
export default {
    name: `cms-block-column`,
    components: {
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock
}
</script>

<style lang="scss" scoped>
</style>
